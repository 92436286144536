define("ember-composability-tools/components/root", ["exports", "@ember/component", "ember-composability-tools/components/node", "@ember/template-factory"], function (_exports, _component, _node, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (element this.tagName) as |Tag|}}
    <Tag
      {{did-insert this.didInsertNode}}
      {{will-destroy this.willDestroyNode}}
      ...attributes
    >
      {{yield (component "node" parent=this)}}
    </Tag>
  {{/let}}
  */
  {
    "id": "ddbUBdVB",
    "block": "[[[44,[[28,[37,1],[[30,0,[\"tagName\"]]],null]],[[[1,\"  \"],[8,[30,1],[[17,2],[4,[38,2],[[30,0,[\"didInsertNode\"]]],null],[4,[38,3],[[30,0,[\"willDestroyNode\"]]],null]],null,[[\"default\"],[[[[1,\"\\n    \"],[18,3,[[50,\"node\",0,null,[[\"parent\"],[[30,0]]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]],[\"Tag\",\"&attrs\",\"&default\"],false,[\"let\",\"element\",\"did-insert\",\"will-destroy\",\"yield\",\"component\"]]",
    "moduleName": "ember-composability-tools/components/root.hbs",
    "isStrictMode": false
  });
  class Root extends _node.default {
    get tagName() {
      return this.args.tagName || 'div';
    }
  }
  _exports.default = Root;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Root);
});