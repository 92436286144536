define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en", {
    "actions": {
      "cancel": "Cancel",
      "create": "Create",
      "fork": "Create new",
      "ignore": "Ignore",
      "reject": "Reject",
      "submit": "Submit",
      "submit-without-notification": "Submit without Notification"
    },
    "admin": {
      "statistics": "Statistics"
    },
    "category-selector": {
      "select-all": "Select all",
      "title": "Category"
    },
    "country-selector": {
      "input-search-placeholder": "Quick Country Lookup...",
      "select-all": "Select all",
      "title": "Countries"
    },
    "crisis24": "Crisis24",
    "error": {
      "error": "Error: ",
      "title": "An error occurred"
    },
    "event-detail": {
      "already-reviewed-similar-events-found-warning": {
        "description": "The following similar events have been already reviewed; please have a closer look before submitting your review.",
        "header": "Beware of possible reviewed duplicates!"
      },
      "automatic-location-selection": "Automatic Location Selection",
      "categories": {
        "1": "Category",
        "2": "Subcategory I",
        "3": "Subcategory II"
      },
      "countrywide": "Countrywide Event:",
      "date-time": "Date / Time (UTC)",
      "end-date-time": "to",
      "end-date-time-clear": "Clear",
      "exop-category": "Category",
      "exop-name-of-event": "Crisis24 Name of Event",
      "horizon-advice": "Advice",
      "horizon-background": "Background",
      "horizon-summary": "Summary",
      "horizon-update-data": "New value (Horizon update data - {date})",
      "impact-of-event": "Impact of Event",
      "injured-value": "Injured: {value, number}",
      "injured-without-value": "Injured:",
      "is_ongoing_completed": "The ongoing event ended on: {date}",
      "killed-injured": "Killed / Injured",
      "killed-value": "Killed: {value, number}",
      "killed-without-value": "Killed:",
      "localized-name-of-event": "Translations",
      "militant-groups": "Militant / Crime Groups",
      "name-of-event": "Name of Event",
      "no": "No",
      "no-location": "No location.",
      "notify-xassist": {
        "label": "Submit Notifications to X-Assist?"
      },
      "ongoing": "Event is ongoing:",
      "ongoing-status": "Ongoing Status",
      "possible-duplicate-events": "Possible duplicate events",
      "review-date-postponed": "({date})",
      "reviewed-horizon-data": "Old value (reviewed Horizon data - {date})",
      "reviewer": "Last Reviewer",
      "select-all": "Select all",
      "select-closest-location": "Select closest location:",
      "select-right-location": "Select right location",
      "targets": "Targets",
      "yes": "Yes"
    },
    "event-reject": {
      "back-to-events": "Back to events",
      "reject": "Reject",
      "rejection-reason": "Rejection reason:"
    },
    "events-grid": {
      "sort-events-by": "Sort events by:",
      "sortable-fields": {
        "date": "First report",
        "impact_score": "Impact",
        "updated_at": "Last update"
      }
    },
    "events-grid-item": {
      "announcement": "Announcement",
      "creation-date": "Event creation date: ",
      "creation-date-tooltip": "Date of the creation of this event (first appearance) in the platform",
      "detail": "Detail",
      "first-reported": "First reported: ",
      "first-reported-tooltip": "Date of the oldest article related to this event",
      "latest-update": "Latest update: ",
      "latest-update-tooltip": "Date of the most recent article related to this event",
      "no-tag": "No tag",
      "pending-horizon-update-review": "Update",
      "reject": "Reject",
      "review": "Review",
      "review-due": "Review due: ",
      "review-due-tooltip": "A previous review was done and this event was marked for re-submission within that due date",
      "reviewer_info": "Reviewed by {reviewer} at: {updated_at}"
    },
    "events-page": {
      "add-new-incident": "Add new incident",
      "auto-refresh": "Auto refresh",
      "events-tag": {
        "title": "Events Tag"
      },
      "events-type": {
        "title": "Events Type",
        "values": {
          "announcements": "Announcements",
          "new-events": "New Events",
          "ongoing": "Ongoing Events",
          "ongoing-completed": "Ongoing Completed Events",
          "pending-horizon-update-review": "Updates"
        }
      },
      "filters": "Filters",
      "show-en-translation": "Prefer English translations",
      "start-date": "Start Date <small>(UTC)</small>"
    },
    "forbidden": "You have unsufficient permissions to perform this operation.",
    "full-category-hierarchy": {
      "indicator": "« {categoryName}"
    },
    "graphiql": "GraphiQL",
    "horizon-alert-id": "Horizon Alert {id}",
    "impact-level-label": {
      "elevated": "elevated",
      "extreme": "extreme",
      "high": "high",
      "low": "low"
    },
    "impact-score-slider": {
      "invalid-value": "Please select a valid impact value.",
      "value": "Value: {score, number} ({label})"
    },
    "impact-selector": {
      "select-all": "Select all",
      "title": "Impact"
    },
    "location-name-missing": "#missing-name#",
    "location-search": {
      "default-text": "Search a location",
      "lat-lon-text": "lat: {lat} lon: {lon}",
      "no-results": "No results."
    },
    "location-type": {
      "adminArea": "Administrative Area",
      "admin_area": "Administrative Area",
      "city": "City",
      "continent": "Continent",
      "country": "Country",
      "innerCountry": "Inner Country",
      "inner_country": "Inner Country"
    },
    "login": {
      "authenticating": "Authenticating..."
    },
    "main-menu": {
      "my-profile": "My Profile",
      "sign-out": "Sign out",
      "title": "Events Review"
    },
    "new-event": {
      "announcement": "Announcement",
      "new-event": "New Event",
      "security_event": "Security Event"
    },
    "no-events-found": "No events found.",
    "old-value": "Old value",
    "page-not-found": "The page you were looking for does not exist.",
    "rejection-reasons": {
      "already_reported": "Already Reported",
      "announcement": "Announcement",
      "covid_19": "COVID-19",
      "duplicate": "Duplicate",
      "illegible_fee_based": "Illegible source (fee-based)",
      "illegible_restricted": "Illegible source (restricted)",
      "illegible_unavailable": "Illegible source (unavailable)",
      "invalid_blog_etc": "Invalid source (blog, newsfeed, news ticker)",
      "invalid_partisan": "Invalid source (partisan)",
      "invalid_unreliable": "Invalid source (unreliable)",
      "irrelevant_incident": "Minor incident",
      "not_an_incident": "Not an incident",
      "too_old": "Too old (although good and never reported)"
    },
    "similar-events-list-item": {
      "make-main": "Make Main"
    },
    "stats": {
      "labels": {
        "end-date": "End Date",
        "start-date": "Start Date"
      }
    },
    "targets": {
      "50001000": "International Officials",
      "50002000": "International Travellers",
      "50003000": "National / Security Officials",
      "50004000": "Local Population",
      "50005000": "Governmental / Security Installations & Public Institutions",
      "50006000": "Health Infrastructure",
      "50007000": "International Governmental Installation",
      "50008000": "Business & Economic Infrastructure"
    }
  }]];
  _exports.default = _default;
});