define("ember-leaflet/components/div-overlay-layer", ["exports", "@ember/component", "@glimmer/tracking", "ember-leaflet/components/base-layer", "@ember/template-factory"], function (_exports, _component, _tracking, _baseLayer, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.popupOpenDidChange}}
    {{did-update this.popupOpenDidChange @popupOpen}}
  {{/if}}
  
  {{#each this.leafletDescriptorsProps as |prop|}}
    {{did-update (fn this.updateOption prop) (get this.args prop)}}
  {{/each}}
  
  <@node @didInsertParent={{this.didInsertParent}} @willDestroyParent={{this.willDestroyParent}}>
    {{#unless this.fastboot.isFastBoot}}
      {{#if this.shouldRender}}
        {{#in-element this.destinationElement~}}
          {{#if this.closePopup}}
            {{yield this.closePopup}}
          {{else}}
            {{yield}}
          {{/if}}
        {{~/in-element}}
      {{/if}}
    {{/unless}}
  </@node>
  */
  {
    "id": "RJMkqm7q",
    "block": "[[[41,[30,0,[\"popupOpenDidChange\"]],[[[1,\"  \"],[1,[28,[35,1],[[30,0,[\"popupOpenDidChange\"]],[30,1]],null]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"leafletDescriptorsProps\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,1],[[28,[37,4],[[30,0,[\"updateOption\"]],[30,2]],null],[28,[37,5],[[30,0,[\"args\"]],[30,2]],null]],null]],[1,\"\\n\"]],[2]],null],[1,\"\\n\"],[8,[30,3],null,[[\"@didInsertParent\",\"@willDestroyParent\"],[[30,0,[\"didInsertParent\"]],[30,0,[\"willDestroyParent\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[51,[30,0,[\"fastboot\",\"isFastBoot\"]]],[[[41,[30,0,[\"shouldRender\"]],[[[40,[[[41,[30,0,[\"closePopup\"]],[[[1,\"          \"],[18,4,[[30,0,[\"closePopup\"]]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[18,4,null],[1,\"\\n\"]],[]]]],[]],\"%cursor:0%\",[28,[37,8],[[30,0,[\"destinationElement\"]]],null]]],[]],null]],[]],null]],[]]]]]],[\"@popupOpen\",\"prop\",\"@node\",\"&default\"],false,[\"if\",\"did-update\",\"each\",\"-track-array\",\"fn\",\"get\",\"unless\",\"in-element\",\"-in-el-null\",\"yield\"]]",
    "moduleName": "ember-leaflet/components/div-overlay-layer.hbs",
    "isStrictMode": false
  });

  /**
   * A base class for PopupLayer and TooltipLayer.
   *
   * @class DivOverlayLayer
   * @extends BaseLayer
   */
  let DivOverlayLayer = (_class = class DivOverlayLayer extends _baseLayer.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "destinationElementTag", 'div');
      _defineProperty(this, "destinationElement", document.createElement(this.destinationElementTag));
      _initializerDefineProperty(this, "shouldRender", _descriptor, this);
      _defineProperty(this, "leafletOptions", [...this.leafletOptions,
      /**
       * The offset of the popup position. Useful to control the anchor of the popup when
       * opening it on some overlays. Defaults to `Point(0, 7)`.
       *
       * @argument offset
       * @type {Point}
       */
      'offset',
      /**
       * A custom CSS class name to assign to the popup.
       *
       * @argument className
       * @type {String}
       */
      'className',
      /**
       * Map pane where the overlay layer will be added.
       *
       * @argument pane
       * @type {String}
       */
      'pane']);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "shouldRender", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = DivOverlayLayer;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DivOverlayLayer);
});