define("ember-leaflet/helpers/ember-leaflet-assign-to", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.emberLeafletAssignTo = emberLeafletAssignTo;
  function emberLeafletAssignTo([object], {
    key,
    value,
    onChange
  }) {
    object[key] = value;

    // we need to send out the assigned object because ember freezes helper arguments
    onChange(object);
  }
  var _default = (0, _helper.helper)(emberLeafletAssignTo);
  _exports.default = _default;
});