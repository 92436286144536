define("ember-composability-tools/components/node", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (component "node" parent=this)}}
  */
  {
    "id": "JYoOQBVE",
    "block": "[[[18,1,[[50,\"node\",0,null,[[\"parent\"],[[30,0]]]]]]],[\"&default\"],false,[\"yield\",\"component\"]]",
    "moduleName": "ember-composability-tools/components/node.hbs",
    "isStrictMode": false
  });
  let Node = (_class = class Node extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "children", new Set());
      _defineProperty(this, "_didSetup", false);
      if (this.args.parent) {
        // register with parent
        this.args.parent.registerChild(this);
      }
    }
    willDestroy() {
      super.willDestroy(...arguments);
      if (this.args.parent) {
        this.args.parent.unregisterChild(this);
      }
      // this hook will be called depth-first from the top-level component
      // since we must destroy childs first, the first parent will
      // be responsible for destroying the children. `this._didSetup` guards
      // that we don't redestroy already destroyed children
      if (this._didSetup) {
        this.children.forEach(c => c.willDestroyNode());
        this.teardown();
        this._didSetup = false;
      }
    }

    /**
     * Method invoked by the child components to register themselves with their parent
     * @param {Component} child
     */
    registerChild(child) {
      this.children.add(child);
      if (this._didSetup) {
        child.setup();
      }
    }

    /**
     * Method invoked by the child components to unregister themselves with their parent
     * @param {Component} child
     */
    unregisterChild(child) {
      this.children.delete(child);
    }

    /**
     * method responsible for setting up itself plus its children
     * it is called by the root initially and recursively to its children
     * @param {HTMLElement} element the root element
     */
    didInsertNode(element) {
      this.setup(element);
      this.children.forEach(c => c.didInsertNode(element));
    }

    /**
     * method responsible for tearing down its children plus itself
     * it is called by the root initially and recursively to its children
     * @param {HTMLElement} element the root element
     */
    willDestroyNode(element) {
      this.children.forEach(c => c.willDestroyNode(element));
      this.teardown(element);
    }

    /**
     * The actual setup logic
     * @param {HTMLElement} element
     */
    setup(element) {
      // library setup code goes here
      if (typeof this.args.didInsertParent === 'function') {
        this.args.didInsertParent(element);
      }
      if (typeof this.didInsertParent === 'function') {
        this.didInsertParent(element);
      }
      this._didSetup = true;
    }

    /**
     * The actual teardown logic
     * @param {HTMLElement} element
     */
    teardown(element) {
      // library teardown code goes here
      if (typeof this.args.willDestroyParent === 'function') {
        this.args.willDestroyParent(element);
      }
      if (typeof this.willDestroyParent === 'function') {
        this.willDestroyParent(element);
      }
      this._didSetup = false;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "didInsertNode", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsertNode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroyNode", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroyNode"), _class.prototype)), _class);
  _exports.default = Node;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Node);
});